import * as React from 'react';
import { SVGProps } from 'react';

const SvgEmptyTableIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={73}
      height={64}
      viewBox="0 0 73 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.5 0h41.667L68 4l4.5 4.5V64h-50V0Z" fill="#899EA2" />
      <path d="M22.5 0h41.667v8.5H72.5V64h-50V0Z" fill="#2B3031" />
      <path
        fill="#899EA2"
        d="M28.5 20h38v4h-38zM28.5 28h38v4h-38zM28.5 36h38v4h-38zM28.5 44h38v4h-38z"
      />
      <path
        d="m16.562 36.598 2.913 3.055L6.188 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
        fill="#8CE1FA"
      />
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={36}
        width={20}
        height={19}
      >
        <path
          d="m16.562 36.598 2.913 3.055L6.188 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
          fill="#D1D5DB"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.89 45.017a20.54 20.54 0 0 1-2.647-2.338 20.545 20.545 0 0 1-2.209-2.754l4.296-3.613c.544.892 1.19 1.74 1.941 2.526a14.9 14.9 0 0 0 2.431 2.059l-3.813 4.12Z"
          fill="#334155"
        />
      </g>
      <rect
        x={28.141}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 28.14 10)"
        fill="#FF974B"
        style={{
          mixBlendMode: 'color',
        }}
      />
      <rect
        x={28.141}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 28.14 10)"
        stroke="#8CE1FA"
        strokeWidth={4.221}
      />
    </svg>
  );
};
export default SvgEmptyTableIllustration;
