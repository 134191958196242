import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={94}
      height={94}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M47 5.875a41.125 41.125 0 1 0 0 82.25 41.125 41.125 0 0 0 0-82.25Zm-5.875 57.548L26.437 48.735l4.673-4.672 10.015 10.014 21.767-21.764 4.687 4.658-26.454 26.452Z"
          fill="#16A34A"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h94v94H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCheckIcon;
