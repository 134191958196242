import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyFactor4X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#DCDCDC"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="m32.063 12.86-8.694-1.278-3.886-7.966a1.095 1.095 0 0 0-1.965 0l-3.887 7.966-8.694 1.278a1.09 1.09 0 0 0-.626.322 1.116 1.116 0 0 0 .02 1.568l6.29 6.2-1.486 8.755a1.117 1.117 0 0 0 .435 1.084 1.087 1.087 0 0 0 1.154.083l7.776-4.134 7.776 4.134c.212.114.459.152.695.11.596-.103.997-.674.894-1.277l-1.486-8.755 6.29-6.2a1.11 1.11 0 0 0 .318-.634 1.102 1.102 0 0 0-.924-1.256Z"
        fill="#F5F5F5"
      />
      <path
        d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm14.204 4.203v1.766H18.03l-.11-1.368 4.743-7.523h1.79l-1.938 3.242-2.36 3.883h6.07Zm-1.29-7.125V23h-2.25V11.625h2.25Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};
export default SvgVerifyFactor4X;
