import * as React from 'react';
import { SVGProps } from 'react';

const SvgProfileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.533 23.667H19.4v-2.134a3.2 3.2 0 0 0-3.2-3.2H9.8a3.2 3.2 0 0 0-3.2 3.2v2.134H4.467v-2.134A5.333 5.333 0 0 1 9.8 16.2h6.4a5.333 5.333 0 0 1 5.333 5.333v2.134ZM13 14.067a6.399 6.399 0 1 1 0-12.8 6.4 6.4 0 1 1 0 12.8Zm0-2.134A4.267 4.267 0 1 0 13 3.4a4.267 4.267 0 0 0 0 8.534Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgProfileIcon;
