import { Theme, ThemeConfig, ThemeDesignConfig } from '../contracts';

import { images } from './config-images';
import { paymentImages } from './config-pm-images';

export const themeConfig: ThemeConfig = {
  defaultTitle: 'RocketChange',
  name: 'RocketChange',

  // images2
  errorIcon: images.errorIcon.path,
  successIcon: images.successIcon.path,
  infoIcon: images.infoIcon.path,
  crossArrowsIcon: `url(${images.crossArrowsIcon.path}) no-repeat 50% / contain`,

  // fonts
  fontFamily: 'Roboto',
  fontFamilyCyrillic: '',
  mainFont: 'Roboto, sans-serif',

  // media
  smallMobileSize: '374px',
  mobileSize: '767px',
  tabletSize: '1024px',

  // jivo site
  jivoMobile: `url(${images.jivoMobile.path}) no-repeat 50% 50% !important`,

  // circle progress bar
  progressCircleDesktopSize: 170,
  progressCircleMobileSize: 140,
  progressCircleTrailColor: '#D7D7D7',

  images,
  paymentImages,
};

export const themeDesignConfig: ThemeDesignConfig = {
  colors: {
    button: {
      fill: {
        primaryBg: '#EA4C2F',
        primaryHover: '#9C3927',
        primaryPressed: '#4E261E',
        disabled: '#475569',
        secondaryBg: '#E4EFEF',
        secondaryHover: '#E4EFEF64',
        secondaryPressed: '#E4EFEF46',
        dangerBg: '#DC2626',
        dangerHover: '#F87171',
        dangerPressed: '#7F1D1D',
        tertiary01: '#000000',
        tertiaryHover01: '#AD5F2620',
        tertiaryPressed01: '#AD5F2640',
        tertiary02: '#000000',
        tertiaryHover02: '#227CEB20',
        tertiaryPressed02: '#227CEB40',
        tertiary03: '#000000',
        tertiaryHover03: '#F8717120',
        tertiaryPressed03: '#F8717140',
        gradient: 'linear-gradient(180deg,#ff974e 0%,#EA4C2F 100%)',
      },
      text: {
        primaryLabel: '#FFFFFF',
        secondaryLabel: '#0F172A',
        tertiaryLabel01: '#F99476',
        tertiaryLabel02: '#0EA5E9',
        tertiaryLabel03: '#F87171',
        linkLabel: '#0EA5E9',
        linkLabelHover: '#7DD3FC',
        linkLabelPressed: '#0284C7',
        textButtonDisable: '#334155',
      },
      border: {
        secondary: '#000000',
        border01: '#F99476',
        border02: '#0EA5E9',
        border03: '#F87171',
        borderDisable: '#475569',
      },
      icon: {
        icon01: '#F99476',
        iconHover01: '#EDAB98',
        iconPressed01: '#EE673F',
        icon02: '#0EA5E9',
        iconHover02: '#7DD3FC',
        iconPressed02: '#0284C7',
        iconDisable: '#475569',
      },
    },
    background: {
      mainPage: '#111718',
      overlay: '#000000CC', // Base/Black/80%
      foreground: '#111718',
      loader: 'rgba(17,23,24,0.4)',
      modal: '#151B1C',
      header: 'rgba(17, 23, 24, 0.80)',
      brand: '#F88360',
      success: '#22C55E',
      warning: '#EAB308',
      danger: '#EF4444',
      info: '#3B82F6',
      inverse: '#F9FAFB',
    },
    layer: {
      layer01: '#181E1F',
      layerHover01: '#2B3031',
      layerSelected01: 'rgba(14, 165, 233, 0.1)',
      layerBrand: 'rgba(248, 131, 96, 0.2)',
      layer02: '#111718',
      layer03: '#2B3031',
      layer04: '#181E1F',
      success: 'rgba(74, 222, 128, 0.2)',
      warning: 'rgba(250, 204, 21, 0.2)',
      danger: 'rgba(239, 68, 68, 0.1)',
      info: 'rgba(59, 130, 246, 0.1)',
    },
    text: {
      primary: '#ECF4F5',
      secondary: 'rgba(255, 255, 255, 0.8)',
      subtle: 'rgba(255, 255, 255, 0.4)',
      placeholder: 'rgba(255, 255, 255, 0.4)',
      inverse: '#000000',
      disabledSubtle: 'rgba(255, 255, 255, 0.2)',
      disabledStrong: 'rgba(255, 255, 255, 0.3)',
      brand: '#F18470',
      success: '#22C55E',
      warning: '#EAB308',
      danger: '#F87171',
      info: '#3B82F6',
    },
    icons: {
      primary: '#ECF4F5',
      secondary: 'rgba(255, 255, 255, 0.8)',
      subtle: 'rgba(255, 255, 255, 0.4)',
      disabledStrong: 'rgba(255, 255, 255, 0.3)',
      inverse: '#000000',
      success: '#22C55E',
      warning: '#EAB308',
      danger: '#F87171',
      info: '#3B82F6',
    },
    border: {
      system: '#323D3F',
      tile: '#323D3F',
      separator: '#2B3031',
      field: 'rgba(0, 0, 0, 0)',
      interactive: '#323D3F',
      hover: '#899EA2',
      focus: '#F88360',
      success: '#22C55E',
      warning: '#EAB308',
      danger: '#EF4444',
      info: '#3B82F6',
    },
    field: {
      default: '#1E2425',
      hover: '#213237',
      focused: '#1A2021',
      disabled: '#474747',
    },
    bonuses: {
      bronze01: '#F59E0B',
      bronze02: 'rgba(245, 158, 11, 0.2)',
      silver01: '#94A3B8',
      silver02: 'rgba(148, 163, 184, 0.2)',
      gold01: '#EAB308',
      gold02: 'rgba(234, 179, 8, 0.2)',
      platinum01: '#93C5FD',
      platinum02: 'rgba(147, 197, 253, 0.2)',
      diamond01: '#67E8F9',
      diamond02: 'rgba(103, 232, 249, 0.2)',
    },
    overlay: {
      '01': 'rgba(255,255,255,0.1)',
      '02': 'rgba(255,255,255,0.2)',
      '03': 'rgba(255,255,255,0.3)',
      '04': 'rgba(255,255,255,0.4)',
      '05': 'rgba(255,255,255,0.5)',
      '06': 'rgba(255,255,255,0.6)',
      '07': 'rgba(255,255,255,0.7)',
      '08': 'rgba(255,255,255,0.8)',
      '09': 'rgba(255,255,255,0.9)',
    },
  },

  spacing: {
    's-none': '0px',
    's-01': '1px',
    's-02': '2px',
    's-04': '4px',
    's-06': '6px',
    's-08': '8px',
    's-10': '10px',
    's-12': '12px',
    's-16': '16px',
    's-20': '20px',
    's-24': '24px',
    's-28': '28px',
    's-32': '32px',
    's-36': '36px',
    's-40': '40px',
    's-44': '44px',
    's-48': '48px',
    's-56': '56px',
    's-64': '64px',
    's-80': '80px',
    'btn-content': '10px',
    'btn-y': '16px',
    'btn-x': '28px',
    'form-y': '40px',
    'form-x': '28px',
    'frame-y': '36px',
    'frame-x': '36px',
    'modal-y': '36px',
    'modal-x': '36px',
  },

  radius: {
    mainStrong: '12px',
    mainSubtle: '4px',
    frame: '24px',
    modal: '24px',
    button: '8px',
    field: '8px',
    layer: '8px',
    card: '10px',
  },

  typography: {
    fontFamily: 'Roboto',
    fontFamilyCyrillic: '',
    mainFont: 'Roboto, sans-serif',
    'display-large': {
      size: '40px',
      height: '48px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'display-medium': {
      size: '32px',
      height: '48px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'heading-large': {
      size: '28px',
      height: '32px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'heading-medium': {
      size: '22px',
      height: '26px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'heading-small': {
      size: '18px',
      height: '22px',
      weight: 700,
      family: `Roboto, sans-serif`,
    },
    'label-large': {
      size: '16px',
      height: '20px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'label-medium': {
      size: '14px',
      height: '20px',
      weight: 600,
      family: `Roboto, sans-serif`,
    },
    'label-small': {
      size: '12px',
      height: '14px',
      weight: 500,
      family: `Roboto, sans-serif`,
    },
    'paragraph-large': {
      size: '16px',
      height: '22px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
    'paragraph-medium': {
      size: '14px',
      height: '20px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
    'paragraph-small': {
      size: '12px',
      height: '14px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
    'overline-medium': {
      size: '12px',
      height: '28px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
    'overline-small': {
      size: '11px',
      height: '14px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
    'overline-x-small': {
      size: '10px',
      height: '14px',
      weight: 400,
      family: `Roboto, sans-serif`,
    },
  },
  illustrations: {
    referral: images.referralIllustration,
    phoneProfile: images.phoneProfileIllustration,
    api: images.apiIllustration,
    emptyTable: images.emptyTableIllustration,
  },
  background: {
    main: `url(${images.mainBg.path}) #111718`,
    mobile: `url(${images.mainBgMobile.path}) no-repeat 50% / cover #111718`,
  },
};

export default { ...themeConfig, ...themeDesignConfig } as Theme;
