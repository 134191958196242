import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor1X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={16}
        cy={16}
        r={15}
        fill="#FF9838"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="m11.422 15.07 1.422-2.523h2.414l-2.406 4.14L15.359 21h-2.421l-1.508-2.656L9.93 21H7.5l2.508-4.313-2.399-4.14h2.422l1.39 2.523ZM21.734 21h-2.257v-8.703l-2.696.836v-1.836l4.711-1.688h.242V21Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgFactor1X;
