import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor4X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M18 34c8.837 0 16-7.163 16-16S26.837 2 18 2 2 9.163 2 18s7.163 16 16 16Z"
          fill="#DCDCDC"
        />
        <path d="M18 35c9.389 0 17-7.611 17-17S27.389 1 18 1 1 8.611 1 18s7.611 17 17 17Z" />
        <path
          d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm14.204 4.203v1.766H18.03l-.11-1.368 4.743-7.523h1.79l-1.938 3.242-2.36 3.883h6.07Zm-1.29-7.125V23h-2.25V11.625h2.25Z"
          fill="#5A5A5A"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFactor4X;
