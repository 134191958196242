import { ImageMap } from '../contracts';

import file1, { ReactComponent as JSX1 } from './public/pm/1.svg';
import file25, { ReactComponent as JSX25 } from './public/pm/25.svg';
import file26, { ReactComponent as JSX26 } from './public/pm/26.svg';
import file27, { ReactComponent as JSX27 } from './public/pm/27.svg';
import file28, { ReactComponent as JSX28 } from './public/pm/28.svg';
import file29, { ReactComponent as JSX29 } from './public/pm/29.svg';
import file30, { ReactComponent as JSX30 } from './public/pm/30.svg';
import file31, { ReactComponent as JSX31 } from './public/pm/31.svg';
import file32, { ReactComponent as JSX32 } from './public/pm/32.svg';
import file33, { ReactComponent as JSX33 } from './public/pm/33.svg';
import file34, { ReactComponent as JSX34 } from './public/pm/34.svg';
import file35, { ReactComponent as JSX35 } from './public/pm/35.svg';
import file36, { ReactComponent as JSX36 } from './public/pm/36.svg';
import file37, { ReactComponent as JSX37 } from './public/pm/37.svg';
import file38, { ReactComponent as JSX38 } from './public/pm/38.svg';
import file39, { ReactComponent as JSX39 } from './public/pm/39.svg';
import file40, { ReactComponent as JSX40 } from './public/pm/40.svg';
import file41, { ReactComponent as JSX41 } from './public/pm/41.svg';
import file42, { ReactComponent as JSX42 } from './public/pm/42.svg';
import file43, { ReactComponent as JSX43 } from './public/pm/43.svg';
import file44, { ReactComponent as JSX44 } from './public/pm/44.svg';
import file45, { ReactComponent as JSX45 } from './public/pm/45.svg';
import file46, { ReactComponent as JSX46 } from './public/pm/46.svg';
import file47, { ReactComponent as JSX47 } from './public/pm/47.svg';
import file48, { ReactComponent as JSX48 } from './public/pm/48.svg';
import file49, { ReactComponent as JSX49 } from './public/pm/49.svg';
import file50, { ReactComponent as JSX50 } from './public/pm/50.svg';
import file51, { ReactComponent as JSX51 } from './public/pm/51.svg';
import file52, { ReactComponent as JSX52 } from './public/pm/52.svg';
import file53, { ReactComponent as JSX53 } from './public/pm/53.svg';
import file54, { ReactComponent as JSX54 } from './public/pm/54.svg';
import file55, { ReactComponent as JSX55 } from './public/pm/55.svg';
import file56, { ReactComponent as JSX56 } from './public/pm/56.svg';
import file57, { ReactComponent as JSX57 } from './public/pm/57.svg';
import file58, { ReactComponent as JSX58 } from './public/pm/58.svg';
import file59, { ReactComponent as JSX59 } from './public/pm/59.svg';
import file60, { ReactComponent as JSX60 } from './public/pm/60.svg';
import file61, { ReactComponent as JSX61 } from './public/pm/61.svg';
import file62, { ReactComponent as JSX62 } from './public/pm/62.svg';
import filedefault, {
  ReactComponent as JSXdefault,
} from './public/pm/default.svg';

export const paymentImages: ImageMap = {
  1: { path: file1, component: JSX1 },
  25: { path: file25, component: JSX25 },
  26: { path: file26, component: JSX26 },
  27: { path: file27, component: JSX27 },
  28: { path: file28, component: JSX28 },
  29: { path: file29, component: JSX29 },
  30: { path: file30, component: JSX30 },
  31: { path: file31, component: JSX31 },
  32: { path: file32, component: JSX32 },
  33: { path: file33, component: JSX33 },
  34: { path: file34, component: JSX34 },
  35: { path: file35, component: JSX35 },
  36: { path: file36, component: JSX36 },
  37: { path: file37, component: JSX37 },
  38: { path: file38, component: JSX38 },
  39: { path: file39, component: JSX39 },
  40: { path: file40, component: JSX40 },
  41: { path: file41, component: JSX41 },
  42: { path: file42, component: JSX42 },
  43: { path: file43, component: JSX43 },
  44: { path: file44, component: JSX44 },
  45: { path: file45, component: JSX45 },
  46: { path: file46, component: JSX46 },
  47: { path: file47, component: JSX47 },
  48: { path: file48, component: JSX48 },
  49: { path: file49, component: JSX49 },
  50: { path: file50, component: JSX50 },
  51: { path: file51, component: JSX51 },
  52: { path: file52, component: JSX52 },
  53: { path: file53, component: JSX53 },
  54: { path: file54, component: JSX54 },
  55: { path: file55, component: JSX55 },
  56: { path: file56, component: JSX56 },
  57: { path: file57, component: JSX57 },
  58: { path: file58, component: JSX58 },
  59: { path: file59, component: JSX59 },
  60: { path: file60, component: JSX60 },
  61: { path: file61, component: JSX61 },
  62: { path: file62, component: JSX62 },
  default: { path: filedefault, component: JSXdefault },
};
