import * as React from 'react';
import { SVGProps } from 'react';

const SvgExamCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#F18470"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.5 16.875v-12.5a.625.625 0 0 1 .625-.625h13.75a.625.625 0 0 1 .625.625v12.5l-2.5-1.25-2.5 1.25-2.5-1.25-2.5 1.25-2.5-1.25-2.5 1.25Z" />
        <path d="m5 12.5 2.5-5 2.5 5M5.625 11.25h3.75M11.25 10H15M13.125 8.125v3.75" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgExamCardIcon;
