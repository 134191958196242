import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotificationGiveaway = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={182}
      height={228}
      viewBox="0 0 182 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M82.364 235.777c18.749 0 33.948-6.616 33.948-14.777 0-8.16-15.199-14.776-33.948-14.776-18.749 0-33.948 6.616-33.948 14.776 0 8.161 15.2 14.777 33.948 14.777Z"
          fill="#505778"
        />
        <path
          d="M75.472 210.074s-5.812 3.312-7.546 6.441c-1.733 3.129-.108 3.394 3.4 2.159 3.507-1.236 8.653-9.055 8.653-9.055l-4.507.455Zm15.213 1.433s-3.739 8.111-4.079 11.776c-.34 3.665 3.114 3.78 5.14 1.303 2.025-2.477 2.515-13.439 2.515-13.439s-3.046 1.432-3.576.36Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.03 104.264s-5.152 12.115-8.727 21.801c-3.576 9.686-4.398 35.457-4.194 37.487.203 2.029 22.173 45.978 23.363 46.541 1.19.564 4.514-.454 4.514-.454L66.214 158.93l7.001-25.48s-1.991 27.719-1.488 30.394c.503 2.674 18.088 47.973 18.088 47.973s2.461 2.26 3.902.754a5.23 5.23 0 0 0 1.217-3.761s-3.698-29.864-4.174-34.615c-.476-4.752-3.1-14.933-3.1-14.933s10.462-34.025 10.578-37.446c.115-3.421 0-11.484 0-11.484s-26.64-1.996-33.207-6.068Z"
          fill="#1D2A30"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m72.046 137.054-2.97-16.426m1.828 11.634-3.657-5.247"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.825 58.788s-3.059-.223-8.049-.902c-4.989-.679-10.985-2.036-12.004-.231-1.02 1.806-10.36 45.476-10.36 47.166 0 1.69 10.7 5.213 17.497 6.57 6.798 1.358 14.955 2.036 15.975 1.134 1.02-.903 13.14-45.028 13.364-46.27.224-1.242-1.468-2.715-3.399-3.394-1.93-.679-13.024-4.072-13.024-4.072Z"
          fill="#F88360"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.507 59.807s-7.138 13.351-10.652 23.077c-3.514 9.727-8.946 27.15-10.985 34.277-2.04 7.127-2.38 16.745-1.815 17.423.564.679 14.05-2.491 18.578-4.188 4.527-1.696 5.329-2.375 6.118-3.278.788-.903-2.828-16.29-2.148-19.683.68-3.394 9.625-24.55 11.78-30.883 2.155-6.333 3.283-11.878 1.815-12.333-1.468-.455-10.312-4.527-12.691-4.412Z"
          fill="#fff"
        />
        <path
          opacity={0.2}
          d="m108.916 85.626-3.004-5.593s-19.244 28.507-20.393 30.102c-.578.801-1.55-1.479-2.386-3.753a519.742 519.742 0 0 0-3.25 10.778c-2.039 7.127-2.379 16.745-1.814 17.424.564.679 14.05-2.491 18.578-4.188 4.527-1.697 5.329-2.376 6.118-3.278.788-.903-2.828-16.29-2.149-19.684.456-2.355 4.902-13.235 8.3-21.808Z"
          fill="#000"
        />
        <path
          d="M96.966 112.416 84.5 114.9l-.557 2.219 12.188-1.941"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.507 59.807s-7.138 13.351-10.652 23.077c-3.514 9.727-8.946 27.15-10.985 34.277-2.04 7.127-2.38 16.744-1.815 17.423.564.679 14.05-2.491 18.578-4.188 4.527-1.696 5.329-2.375 6.118-3.278.788-.903-2.828-16.29-2.148-19.683.68-3.394 9.625-24.55 11.78-30.883 2.155-6.333 3.283-11.878 1.815-12.333-1.468-.455-10.312-4.527-12.691-4.412Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.271 58.448a171.484 171.484 0 0 0-7.933 15.496c-3.963 8.824-9.625 18.441-11.896 22.969a58.727 58.727 0 0 0-3.398 8.253s.115-9.95.57-16.059c.456-6.108 1.36-12.787 1.129-14.145-.231-1.357-15.744-3.278-22.317-5.538-6.573-2.26 1.475-10.52 3.739-10.52 2.263 0 13.82 1.017 17.558-.571 3.739-1.588 4.31-2.26 7.933-2.26s14.615 2.375 14.615 2.375Z"
          fill="#fff"
        />
        <path
          opacity={0.2}
          d="M75.832 85.022c1.733-3.258 3.521-6.788 5.092-10.181l-9.042-3.265-4.805-7.54v6.169l-3.202-4.337-8.47-3.204-9.3-1.914c-2.718 2.572-5.274 7.086-.68 8.668 6.574 2.26 22.093 4.18 22.318 5.538.142.862-.163 3.855-.53 7.466l8.619 2.6Z"
          fill="#000"
        />
        <path
          d="M89.271 58.448a171.484 171.484 0 0 0-7.933 15.496c-3.963 8.824-9.625 18.441-11.896 22.969a58.727 58.727 0 0 0-3.398 8.253s.115-9.95.57-16.059c.456-6.108 1.36-12.787 1.129-14.145-.231-1.357-15.744-3.278-22.317-5.538-6.573-2.26 1.475-10.52 3.739-10.52 2.263 0 13.82 1.017 17.558-.571 3.739-1.588 4.31-2.26 7.933-2.26s14.615 2.375 14.615 2.375Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m67.594 67.32.15 7.643-3.475-7.921"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.535 55.959s-2.264.678-2.604 1.696c-.34 1.018-.455 4.982-1.475 6-1.02 1.019-4.642 2.824-3.507 3.163 1.135.34 6.566-2.26 6.566-2.26s1.815-5.77 2.155-7.011c.34-1.242-1.135-1.588-1.135-1.588Zm2.155 6.902s-1.02 8.708-.224 8.03c.795-.68.788-2.145 2.039-3.503 1.25-1.357 6.458-4.303 7.246-5.885.789-1.581.795-3.848.231-4.411a2.12 2.12 0 0 0-1.359-.564s-7.369 4.412-8.049 4.636c-.68.224.116 1.697.116 1.697Z"
          fill="#F88360"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.33 52.116s-1.135 4.975-1.36 6.556c-.224 1.582-1.583 9.734-1.583 9.734s4.187-5.546 4.643-5.885c.455-.34 7.361-3.618 7.817-4.751.455-1.134 3.059-8.37 3.059-8.37s-8.042 4.413-12.576 2.716Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.403 32.508s-3.901 10.018-4.323 13.08c-.421 3.06.422 8.49 4.18 9.74 3.76 1.248 8.919-2.505 10.034-3.618a21.792 21.792 0 0 0 2.23-2.783s4.044-5.01 4.881-6.68c.836-1.669 3.623-6.264 2.508-9.739-1.115-3.475-8.504-4.453-12.542-3.896-4.038.557-6.968 3.896-6.968 3.896Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.884 40.3s-3.202 1.113-3.48 1.948c-.28.835 1.808 1.948 1.808 1.948m-3.202 1.527s.68 1.256 3.48 1.392c1.65.09 3.28-.403 4.603-1.392 0 0-2.278 3.903-3.671 4.88a2.763 2.763 0 0 1-2.807.177 6.237 6.237 0 0 0-.49-2.552l-1.115-2.505Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.522 48.322s-3.874 1.887-5.404-.095"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m100.337 45.254 1.747.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.73 30.281s-3.338 2.281-5.622-1.581c-2.42-4.072 7.41-7.16 17.443-5.355 10.034 1.805 10.877 7.242 10.312 10.995-.564 3.754-5.295 10.181-5.295 10.181s-.836 1.528-1.53-.142c-.693-1.67.837-3.896.973-4.588.136-.693-3.066-1.256-2.366-2.926.7-1.67 2.087-.97.972-2.64-1.115-1.67-4.323-1.113-9.061-.278-4.738.834-9.17-.353-5.826-3.666Z"
          fill="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M114.11 33.274a2.717 2.717 0 0 1 .768 2.097m-18.524-7.295s-3.909 2.206-.85 3.393c3.06 1.188 7.308-2.884 13.256-1.187a12.804 12.804 0 0 1 4.289 1.982m-6.838-3.849s7.477-.17 9.177 3.733m-5.608 3.903s2.719 1.697 3.229 4.073"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m110.629 44.874-3.174-5.138c0-.142.041-.285.054-.434a.337.337 0 0 0-.265-.36l-6.764-1.507a.297.297 0 0 0-.156 0l-2.135-.678a.334.334 0 0 0-.176-.102l-6.764-1.514a.341.341 0 0 0-.401.245c0 .094-.632 2.395-.38 3.604.271 1.357 2.113 2.524 3.874 2.524h.04c1.49 0 3.189-1.357 3.74-3.529.264-.109.552-.146.836-.108.34.06.68.461.883.814a7.597 7.597 0 0 0-.142 2.545c.272 1.358 2.107 2.532 3.874 2.532h.027a3.997 3.997 0 0 0 3.25-2.267l3.025 3.957.754-.584Z"
          fill="#263238"
        />
        <path
          d="M107.326 40.38c-2.203 1.602-4.079.509-6.003 0a3.718 3.718 0 0 0-1.672-.109c-.023.333-.007.668.048.998.272 1.357 2.107 2.532 3.874 2.532h.027a3.995 3.995 0 0 0 3.25-2.267c.034-.425.2-.829.476-1.154Zm-16.859-1.35c.272 1.357 2.115 2.524 3.875 2.524h.04a4.081 4.081 0 0 0 3.359-2.504 4.224 4.224 0 0 0-.619.04c-2.719.36-2.501-1.1-4.806-1.1a3.9 3.9 0 0 0-1.91.449c.002.198.022.396.061.59Z"
          fill="#F88360"
        />
        <path
          d="m110.629 44.874-3.174-5.138c0-.142.041-.285.054-.434a.337.337 0 0 0-.265-.36l-6.764-1.507a.297.297 0 0 0-.156 0l-2.135-.678a.334.334 0 0 0-.176-.102l-6.764-1.514a.341.341 0 0 0-.401.245c0 .094-.632 2.395-.38 3.604.271 1.357 2.113 2.524 3.874 2.524h.04c1.49 0 3.189-1.357 3.74-3.529.264-.109.552-.146.836-.108.34.06.68.461.883.814a7.597 7.597 0 0 0-.142 2.545c.272 1.358 2.107 2.532 3.874 2.532h.027a3.996 3.996 0 0 0 3.25-2.267l3.025 3.957.754-.584Z"
          strokeMiterlimit={10}
        />
        <path
          d="M107.224 46.28s3.623-4.032 4.758-2.084c1.136 1.948-2.644 5.009-3.759 5.009a4.08 4.08 0 0 1-2.236-.977"
          fill="#fff"
        />
        <path
          d="M107.224 46.28s3.623-4.032 4.758-2.084c1.136 1.948-2.644 5.009-3.759 5.009a4.08 4.08 0 0 1-2.236-.977"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m58.002 70.443 1.815-7.242-3.304-.584s-1.176 5.138 1.49 7.826Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m59.586 68.067 3.97-4.982-3.74.116s-1.019 3.17-.23 4.866Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.684 58.565s3.855-3.394 5.778-3.625a40.596 40.596 0 0 0 6.913-1.805c1.136-.455 2.135-.679 1.612.462-.748 1.608-3.311 2.137-3.311 2.137s6.118-1.581 7.022-1.242c.904.34.34 1.358-.34 1.697-.68.34-1.468.788-1.468.788s4.983-2.037 5.438-.564c.455 1.473-3.175 2.6-3.175 2.6s2.155-.109 1.924.91c-.23 1.017-5.207 2.483-5.887 2.823a9.683 9.683 0 0 1-2.494.455s3.059 1.242 3.059 1.696c0 .455.564 1.473-.456 1.697-1.02.224-1.128-.794-2.943-1.357-1.815-.563-3.283-.455-5.554-.903a13.854 13.854 0 0 1-3.963-1.473l-8.042 2.152 1.7-6.679 4.187.23Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m75.676 55.735-3.739 1.242m8.953 0-5.214 1.472m7.477.564L76.471 60.6m-5.214-1.81a5.885 5.885 0 0 1-4.078 1.92"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m62.42 58.226-4.643-.34a30.516 30.516 0 0 0-1.02 3.734c-.455 2.145.11 6.108.11 6.108l2.719.34s1.59-6.224 2.834-9.842Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.586 57.994a27.862 27.862 0 0 0-1.7 6.109 30.493 30.493 0 0 0 .116 6.34s-12.692-1.019-16.315-1.019c-3.623 0-5.553-6-3.174-8.715s6.118-3.617 9.408-3.278c3.29.34 11.665.563 11.665.563Z"
          fill="#fff"
        />
        <path
          opacity={0.2}
          d="M42.822 65.188h-5.214c.456 2.219 1.979 4.235 4.079 4.235 3.623 0 16.314 1.018 16.314 1.018a30.499 30.499 0 0 1-.115-6.34s-9.116 1.087-15.064 1.087Z"
          fill="#000"
        />
        <path
          d="M59.586 57.994a27.862 27.862 0 0 0-1.7 6.109 30.493 30.493 0 0 0 .116 6.34s-12.692-1.019-16.315-1.019c-3.623 0-5.553-6-3.174-8.715s6.118-3.617 9.408-3.278c3.29.34 11.665.563 11.665.563Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.732 90.575s-16.11 12.828-21.487 16.968c-6.186 4.751-9.748 6.978-8.762 8.6.985 1.622 8.442 7.5 12.025 9.115 3.582 1.616 18.224-34.683 18.224-34.683Z"
          fill="#fff"
        />
        <path
          opacity={0.2}
          d="M72.732 90.575s-16.11 12.828-21.487 16.968c-6.186 4.751-9.748 6.978-8.762 8.6.985 1.622 8.442 7.5 12.025 9.115 3.582 1.616 18.224-34.683 18.224-34.683Z"
          fill="#000"
        />
        <path
          d="M72.732 90.575s-16.11 12.828-21.487 16.968c-6.186 4.751-9.748 6.978-8.762 8.6.985 1.622 8.442 7.5 12.025 9.115 3.582 1.616 18.224-34.683 18.224-34.683Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.112 67.456s-2.365-4.29-3.86-5.776c-1.496-1.486-2.978-3.237-2.978-3.237l8.837-1.487s2.597 4.914 3.8 6.53a25.642 25.642 0 0 1 2.427 5.022l-8.226-1.052Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m75.166 58.707-4.997.76s3.678 4.813 4.555 7.086l5.785.618s-4.031-7.419-5.343-8.464Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.294 62.433c.497.801.436 1.745-.142 2.098-.578.352-1.448 0-1.944-.801-.497-.801-.436-1.745.142-2.098.578-.353 1.448 0 1.945.801Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.751 66.2s-1.882-4.527-3.194-6.17a68.491 68.491 0 0 1-2.61-3.542l8.965-.51s2.04 5.172 3.053 6.903c1.013 1.731 1.862 5.26 1.862 5.26l-8.076-1.94Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m76.763 57.614-5.057.231s3.133 5.192 3.752 7.548l5.683 1.242s-3.188-7.847-4.378-9.02Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.477 61.443c.401.855.238 1.778-.374 2.07-.612.292-1.441-.163-1.842-1.011-.401-.849-.245-1.779.367-2.07.612-.292 1.462.163 1.849 1.011Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.484 67.524s-1.4-4.69-2.535-6.461a67.308 67.308 0 0 1-2.223-3.794l8.966.427s1.489 5.355 2.318 7.181c.83 1.826 1.299 5.43 1.299 5.43l-7.825-2.783Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m80.373 59.195-5.044-.292s2.57 5.491 2.937 7.9l5.526 1.827s-2.359-8.125-3.419-9.435Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.686 63.085c.312.889.054 1.791-.585 2.036-.64.244-1.414-.312-1.727-1.201-.312-.89-.054-1.792.585-2.037.639-.244 1.414.299 1.727 1.202Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.97 75.642a58.464 58.464 0 0 1-3.623-4.976c-.57-1.133.45-2.26-1.36-4.187-1.807-1.928-3.174-3.055-3.513-3.842-.34-.787-1.136-.57-1.584.34-.449.909.34 2.375 1.02 2.714.487.287.91.67 1.243 1.127 0 0-3.514-1.018-4.534-1.242-1.02-.224-6.002-2.26-6.797-2.376-.796-.115-2.488-.455-2.264.903.224 1.358 2.04 2.036 2.04 2.036s.224 3.055 1.474 4.867c1.251 1.812 3.4 2.49 5.099 3.394 1.699.902 6.117 1.581 6.117 1.581l3.284 4.412 3.399-4.751Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.597 66.14s3.005 2.158 4.317 3.122a6.254 6.254 0 0 0 2.284 1.14m-6.138-1.927s1.753 1.487 2.807 2.362a8.682 8.682 0 0 0 3.066 1.31"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m93.914 78.248-2.828-3.285-5.669 7.242 2.495 2.715 6.002-6.672Z"
          fill="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106.83 62.746s11.895 1.812 13.486 2.376c1.591.563 2.148 2.83.789 7.357-1.36 4.528-7.362 29.41-8.606 30.768-1.244 1.357-6.234.678-12.012-3.51-5.778-4.187-13.71-14.362-13.71-14.362l7.028-7.805 10.985 9.387 2.95-14.03"
          fill="#fff"
        />
        <path
          opacity={0.2}
          d="M112.498 103.245c1.244-1.357 7.247-26.247 8.606-30.767 1.36-4.52.796-6.787-.788-7.358l-.129-.04c-1.061 4.072-5.813 22.398-6.424 27.068-.68 5.253-2.291 6.855-10.985 3.394-6.988-2.756-13.535-8.315-15.832-10.392l-.17.19s7.933 10.181 13.711 14.362c5.778 4.181 10.761 4.901 12.011 3.543Z"
          fill="#000"
        />
        <path
          d="M106.83 62.746s11.895 1.812 13.486 2.376c1.591.563 2.148 2.83.789 7.357-1.36 4.528-7.362 29.41-8.606 30.768-1.244 1.357-6.234.678-12.012-3.51-5.778-4.187-13.71-14.362-13.71-14.362l7.028-7.805 10.985 9.387 2.95-14.03"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M104.79 86.957s6.009 3.849 7.138 4.073l-7.138-4.073Z"
          fill="#fff"
        />
        <path
          d="M104.79 86.957s6.009 3.849 7.138 4.073"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m38.261 33.797-3.086 7.493 14.622 3.041 2.678-6.828-14.214-3.706Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m39.892 35.398-2.168 5.26 10.27 2.139 1.877-4.799-9.979-2.6Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.759 38.31a1.823 1.823 0 0 1-1.22 2.58 1.831 1.831 0 0 1-2.25-1.76 1.825 1.825 0 0 1 1.145-1.71 1.758 1.758 0 0 1 2.325.89ZM15.998 50.114l6.927 4.894 10.17-9.998-6.383-4.35-10.713 9.454Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m18.541 49.429 4.867 3.44 7.138-7.024-4.48-3.054-7.525 6.638Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.502 46.001c1.129.034 2.04.733 2.04 1.568 0 .835-.939 1.48-2.04 1.446-1.1-.034-2.039-.733-2.005-1.568.034-.835.89-1.48 2.005-1.446Zm-9.842 64.826 7.769-3.088-5.812-12.998-7.009 2.912 5.051 13.174Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m15.026 108.472 5.459-2.172-4.085-9.13-4.922 2.05 3.548 9.252Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.784 103.94c.687-.744.694-1.854.015-2.479-.678-.626-1.786-.53-2.473.214-.688.744-.695 1.854-.016 2.479.679.626 1.786.53 2.474-.214Zm117.318-49.265 1.95 8.125 13.718-3.89-1.89-7.337-13.778 3.102Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m136.386 55.374 1.366 5.708 9.639-2.735-1.325-5.152-9.68 2.18Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.897 55.333a1.846 1.846 0 0 1 .463 2.491 1.672 1.672 0 0 1-1.151.65 1.67 1.67 0 0 1-1.263-.392 1.868 1.868 0 0 1-.469-2.498 1.69 1.69 0 0 1 1.157-.643 1.68 1.68 0 0 1 1.263.392Zm-8.619-18.007 8.355.407.108-14.24-7.586-.244-.877 14.077Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m135.59 35.33 5.867.292.081-10.005-5.329-.17-.619 9.883Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.378 29.08a1.85 1.85 0 0 1 2.522.251 1.664 1.664 0 0 1 .048 1.916c-.127.189-.291.35-.483.473a1.85 1.85 0 0 1-2.522-.25 1.664 1.664 0 0 1-.048-1.916c.127-.19.291-.35.483-.474Zm8.789 104.335 8.348.407.109-14.233-7.58-.251-.877 14.077Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m147.479 131.427 5.867.285.075-9.997-5.33-.177-.612 9.889Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.26 125.175a1.86 1.86 0 0 1 2.529.252 1.647 1.647 0 0 1 .298 1.285 1.668 1.668 0 0 1-.733 1.097 1.863 1.863 0 0 1-2.529-.244 1.66 1.66 0 0 1-.298-1.289 1.684 1.684 0 0 1 .733-1.101Zm10.598-12.631 8.347.408.109-14.24-7.579-.245-.877 14.077Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m161.17 110.556 5.866.285.075-10.005-5.329-.169-.612 9.889Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.951 104.306a1.862 1.862 0 0 1 2.529.244 1.663 1.663 0 0 1 .045 1.914 1.67 1.67 0 0 1-.48.475 1.86 1.86 0 0 1-2.529-.251 1.659 1.659 0 0 1 .435-2.382Zm-55.531 23.612 6.261 5.539 9.014-11.03-5.758-4.934-9.517 10.425Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m109.691 127.184 4.398 3.896 6.335-7.751-4.044-3.468-6.689 7.323Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.007 123.425a1.863 1.863 0 0 1 1.815 1.778 1.67 1.67 0 0 1-1.163 1.52 1.666 1.666 0 0 1-.673.069 1.846 1.846 0 0 1-1.815-1.772 1.672 1.672 0 0 1 1.836-1.595ZM5.836 171.527l8.293 1.086 1.264-14.179-7.538-.862-2.02 13.955Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m7.304 169.654 5.826.76.89-9.964-5.295-.604-1.42 9.808Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.595 163.566a1.849 1.849 0 0 1 2.494.454 1.667 1.667 0 0 1-.108 1.909 1.672 1.672 0 0 1-.517.433 1.857 1.857 0 0 1-2.502-.455 1.663 1.663 0 0 1 .116-1.908 1.66 1.66 0 0 1 .517-.433Zm109.477 12.76 8.293 1.079 1.271-14.179-7.539-.862-2.025 13.962Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m120.547 174.445 5.825.76.891-9.957-5.295-.611-1.421 9.808Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.831 168.358a1.857 1.857 0 0 1 2.501.454c.12.194.199.41.233.635a1.682 1.682 0 0 1-.858 1.714 1.874 1.874 0 0 1-2.502-.455 1.675 1.675 0 0 1-.195-1.309 1.677 1.677 0 0 1 .821-1.039Zm3.011-81.395 1.951 8.125 13.711-3.89-1.883-7.337-13.779 3.102Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m128.12 87.663 1.373 5.708 9.632-2.729-1.325-5.158-9.68 2.179Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M134.632 87.62a1.858 1.858 0 0 1 .469 2.498 1.676 1.676 0 0 1-1.828.577 1.676 1.676 0 0 1-.592-.326 1.854 1.854 0 0 1-.462-2.498 1.67 1.67 0 0 1 1.823-.576c.217.067.417.178.59.325ZM21.933 139.029l1.95 8.125 13.718-3.89-1.89-7.337-13.778 3.102Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m24.217 139.728 1.366 5.708 9.64-2.728-1.326-5.159-9.68 2.179Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.73 139.688a1.858 1.858 0 0 1 .462 2.498 1.675 1.675 0 0 1-2.414.251 1.852 1.852 0 0 1-.469-2.491 1.675 1.675 0 0 1 2.42-.258ZM60.034 39.613l-5.656 5.81 12.44 8.253 5.023-5.348-11.807-8.715Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m60.944 41.704-3.97 4.086 8.742 5.797 3.528-3.76-8.3-6.123Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.487 48.268c1.006 0 1.822-.754 1.822-1.683 0-.93-.816-1.684-1.822-1.684-1.006 0-1.822.754-1.822 1.684 0 .93.816 1.683 1.822 1.683Zm-15.54-3.441-3.086 7.494 14.622 3.04 2.678-6.828-14.214-3.706Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m49.58 46.43-2.17 5.26 10.272 2.138 1.876-4.8-9.979-2.599Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.446 49.34a1.824 1.824 0 0 1-1.22 2.581 1.831 1.831 0 0 1-2.25-1.76 1.824 1.824 0 0 1 1.145-1.71 1.758 1.758 0 0 1 2.325.89ZM57.53 14.6l8.355-.428L64.573 0 57 .502l.53 14.098Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m58.639 12.489 5.866-.299-.918-9.957-5.322.353.373 9.903Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.788 6.088a1.864 1.864 0 0 1 2.542 0 1.662 1.662 0 0 1-.197 2.416 1.85 1.85 0 0 1-2.536 0 1.67 1.67 0 0 1 .19-2.416ZM.48 67.816l8.36 1.44 4.868-13.386-7.634-1.194-5.595 13.14Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m2.478 66.105 5.873 1.01 3.426-9.4-5.363-.835-3.936 9.224Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.393 60.45c1.027-.454 2.142-.203 2.488.55.347.754-.21 1.745-1.244 2.193-1.033.448-2.14.203-2.487-.55-.347-.753.203-1.744 1.243-2.192ZM-.208 35.711l5.343 6.577 12.495-6.869-4.963-5.918-12.875 6.21Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m2.423 35.738 3.753 4.622 8.775-4.826-3.48-4.154-9.048 4.358Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.113 34.046c1.074.34 1.747 1.256 1.509 2.037-.238.78-1.306 1.167-2.38.828-1.074-.34-1.747-1.256-1.509-2.037.238-.78 1.285-1.167 2.38-.828Z"
          fill="#92E3A9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h182v228H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgNotificationGiveaway;
