import * as React from 'react';
import { SVGProps } from 'react';

const SvgLottery = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={156}
      height={60}
      viewBox="0 0 156 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M156 0H0v64.609h156V0Z" fill="#550CB2" />
      <path d="M156 0H0v64.609h156V0Z" fill="url(#a)" />
      <path d="M156 0H0v64.609h156V0Z" fill="url(#b)" />
      <path
        d="M152.1 60.798H3.714V3.81H152.1v56.987ZM5.385 59.346H150.8V5.263H5.385v54.083Z"
        fill="#404865"
      />
      <path d="m78 13.248 2.6.908 2.786-2.722L78 13.248Z" fill="#fff" />
      <path
        d="m78 13.248 5.386-1.814-3.715-.545L78 13.25Zm0 0-3.343 4.538L78 15.97v-2.723Z"
        fill="#fff"
      />
      <path d="M78 13.248v2.723l3.343 1.815L78 13.248Z" fill="#fff" />
      <path
        d="m78 13.248 3.343 4.538-.743-3.63-2.6-.908Zm-2.786.908-.557 3.63L78 13.248l-2.786.908Z"
        fill="#fff"
      />
      <path
        d="m78 13.248 1.671-2.359L78 7.622v5.626Zm0 0-5.571-1.814 2.785 2.722L78 13.248Z"
        fill="#fff"
      />
      <path d="m78 7.622-1.67 3.267 1.67 2.36V7.621Z" fill="#fff" />
      <path
        d="m78 13.248-1.67-2.358-3.9.545L78 13.249Zm12.443 1.271 2.043.544 2.043-1.815-4.086 1.27Z"
        fill="#fff"
      />
      <path
        d="m90.443 14.519 4.086-1.27-2.786-.545-1.3 1.815Zm0 0-2.415 3.266 2.415-1.27V14.52Zm0 0v1.996l2.415 1.27-2.415-3.266Z"
        fill="#fff"
      />
      <path d="m90.443 14.519 2.415 3.266-.372-2.722-2.043-.544Z" fill="#fff" />
      <path
        d="m88.4 15.063-.372 2.722 2.415-3.266-2.043.544Zm2.043-.544 1.3-1.815-1.3-2.36v4.175Zm0 0-3.9-1.27 1.857 1.814 2.043-.544Zm0-4.174-1.3 2.359 1.3 1.633v-3.992Zm0 4.174v-.182l-1.3-1.633-2.6.544 3.9 1.27Zm-24.885 0 1.857.544 2.043-1.815-3.9 1.27Z"
        fill="#fff"
      />
      <path
        d="m65.558 14.519 3.9-1.27-2.786-.545-1.114 1.815Zm-.001 0-2.6 3.266 2.6-1.27V14.52Zm.001 0v1.996l2.414 1.27-2.414-3.266Z"
        fill="#fff"
      />
      <path
        d="m65.558 14.519 2.414 3.266-.557-2.722-1.857-.544Zm-2.044.544-.557 2.722 2.6-3.266-2.043.544Z"
        fill="#fff"
      />
      <path
        d="m65.558 14.519 1.114-1.815-1.114-2.36v4.175Zm-.001 0-4.085-1.27 2.042 1.814 2.043-.544Zm.001-4.174-1.3 2.359 1.3 1.633v-3.992Zm-.001 4.174v-.182l-1.3-1.633-2.785.544 4.085 1.27Zm-15 18.208h1.932l.967 5.1c.193 1.134.386 2.078.58 3.211.193-1.133.386-2.077.58-3.21l1.353-5.1h1.546l1.353 5.1c.193 1.133.386 2.077.773 3.21.193-1.133.387-2.077.58-3.21l.966-5.1h1.353l-1.932 10.2h-2.32l-1.353-5.29c-.193-.755-.387-1.51-.387-2.266-.193.755-.386 1.51-.386 2.266l-1.353 5.29h-2.32l-1.932-10.2Zm13.53 0h1.74v10.2h-1.74v-10.2Zm4.445 0h1.933l3.286 5.667.966 2.078c0-.945-.193-2.267-.193-3.4v-4.345h1.74v10.2H74.33l-3.286-5.666-.966-2.078c0 1.133.193 2.266.193 3.4v4.344h-1.74v-10.2Zm10.438 0h1.933l3.285 5.667.967 2.078c0-.945-.193-2.267-.193-3.4v-4.345h1.74v10.2h-1.934l-3.286-5.666-.966-2.078c0 1.133.193 2.266.193 3.4v4.344h-1.74v-10.2Zm10.244 0h6.185v1.511h-4.446v2.645h3.673v1.51h-3.673v3.023H95.4v1.511h-6.38v-10.2h.194Zm14.11 10.2-2.126-3.966h-1.546v3.966h-1.74v-10.01h3.286c2.126 0 3.866.755 3.866 3.021 0 1.511-.773 2.456-2.126 2.834l2.512 4.155h-2.126Zm-3.672-5.477h1.546c1.353 0 2.126-.567 2.126-1.7 0-1.134-.773-1.511-2.126-1.511h-1.546v3.21Zm-54.389.098H7.39v1.667h37.873v-1.667Zm0-2.501H18.33v.833h26.932v-.833Zm0 5.836H18.33v.834h26.932v-.834Zm101.706-3.332h-36.948v1.633h36.948V37.55Zm-9.853-2.449h-27.095v.817h27.095v-.817Zm0 5.715h-27.095v.816h27.095v-.816Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="a"
          x1={109.77}
          y1={0.251}
          x2={39.811}
          y2={91.396}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7843BA" />
          <stop offset={1} stopColor="#550CB2" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={0.424}
          y1={32.325}
          x2={155.216}
          y2={16.228}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F88360" />
          <stop offset={1} stopColor="#DB7354" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgLottery;
