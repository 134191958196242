import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m14.656 7.149-2.819 2.46.845 3.662a1 1 0 0 1-1.49 1.084l-3.195-1.938-3.187 1.938a1 1 0 0 1-1.49-1.084l.843-3.659-2.82-2.463a1 1 0 0 1 .57-1.754l3.716-.322 1.451-3.46a.997.997 0 0 1 1.84 0l1.455 3.46 3.715.322a1 1 0 0 1 .57 1.754h-.004Z"
        fill="#FACA47"
      />
    </svg>
  );
};
export default SvgStarIcon;
