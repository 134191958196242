import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyBonusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={47}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.4}>
        <path
          d="m41.34 23.13-8.898-1.293-3.978-8.065c-.109-.22-.288-.4-.508-.508a1.126 1.126 0 0 0-1.504.508l-3.978 8.065-8.899 1.293a1.12 1.12 0 0 0-.62 1.914l6.438 6.277-1.52 8.864a1.12 1.12 0 0 0 1.625 1.18l7.96-4.184 7.96 4.185a1.12 1.12 0 0 0 1.626-1.181l-1.521-8.864 6.438-6.277a1.12 1.12 0 0 0 .326-.642 1.117 1.117 0 0 0-.947-1.272Z"
          fill="#F88360"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.334 28.7a2.204 2.204 0 0 0-.629-.72 4.174 4.174 0 0 0-.956-.522c-.37-.147-.774-.287-1.211-.421a15.217 15.217 0 0 1-.598-.192 2.537 2.537 0 0 1-.43-.19.851.851 0 0 1-.26-.217.456.456 0 0 1-.086-.279c0-.106.026-.197.08-.271a.588.588 0 0 1 .216-.182c.091-.045.196-.078.316-.1.12-.02.248-.031.384-.031.132 0 .262.018.39.054a2.356 2.356 0 0 1 .69.324c.102.069.194.136.277.2l.055.047c.058.046.11.084.158.114s.1.046.158.046c.07 0 .14-.032.21-.097l.948-.935a.914.914 0 0 0 .121-.143.25.25 0 0 0 .04-.138c0-.049-.027-.109-.083-.178a1.681 1.681 0 0 0-.226-.22 3.463 3.463 0 0 0-.331-.238 7.3 7.3 0 0 0-.4-.235 4.139 4.139 0 0 0-1.019-.375c-.104-.024-.213-.037-.32-.054v-.775a.213.213 0 0 0-.1-.184.355.355 0 0 0-.196-.068h-1.165c-.09 0-.159.025-.203.075a.252.252 0 0 0-.07.177v.804c-.128.024-.262.042-.383.076-.405.113-.75.273-1.038.48a2.172 2.172 0 0 0-.662.744c-.155.29-.232.613-.232.969 0 .332.062.636.185.911.124.276.31.527.558.754.248.228.557.435.929.62.371.185.805.358 1.3.518.112.035.243.078.397.13.153.051.3.113.44.186s.259.155.356.249c.097.094.146.2.146.318a.885.885 0 0 1-.053.304.621.621 0 0 1-.177.253.908.908 0 0 1-.338.174 1.806 1.806 0 0 1-.529.066c-.227 0-.445-.036-.654-.109a3.078 3.078 0 0 1-.622-.303 3.7 3.7 0 0 1-.375-.267 11.05 11.05 0 0 0-.269-.215 1.153 1.153 0 0 0-.148-.103.245.245 0 0 0-.118-.035c-.054 0-.124.03-.211.086l-1.071.946c-.095.08-.143.153-.143.218 0 .061.048.137.143.23l.024.023.025.022.019.017c.198.203.426.392.684.568.258.176.533.33.824.462.29.132.595.235.91.309.028.007.056.009.084.015v.842c0 .169.091.252.272.252h1.165c.198 0 .297-.083.297-.252v-.824c.185-.032.37-.066.545-.119a3.68 3.68 0 0 0 1.143-.56 2.67 2.67 0 0 0 .765-.873 2.36 2.36 0 0 0 .278-1.143c0-.398-.075-.737-.226-1.016"
          fill="#111718"
        />
      </g>
      <path
        d="m35.34 16.914-9.492-1.38-4.243-8.602a1.19 1.19 0 0 0-.543-.542 1.201 1.201 0 0 0-1.603.542l-4.243 8.602-9.493 1.38a1.194 1.194 0 0 0-.661 2.041l6.867 6.696-1.622 9.454a1.195 1.195 0 0 0 1.734 1.26l8.49-4.464 8.49 4.464a1.194 1.194 0 0 0 1.735-1.26l-1.622-9.454L36 18.955c.187-.183.31-.422.348-.684.101-.654-.355-1.26-1.01-1.357Z"
        fill="#F88360"
      />
      <path
        d="M25.012 21.53v1.987h-8.438V21.53h8.438Zm-3.146-3.39v8.962h-2.139V18.14h2.14Z"
        fill="#111718"
      />
    </svg>
  );
};
export default SvgVerifyBonusIcon;
