import * as React from 'react';
import { SVGProps } from 'react';

const SvgBookMarkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="#F18470"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.5 3v9l-3-2.25-3 2.25V3" />
        <path d="M4.5 20.25A2.25 2.25 0 0 1 6.75 18H19.5V3H6.75A2.25 2.25 0 0 0 4.5 5.25v15ZM4.5 20.25V21H18" />
      </g>
    </svg>
  );
};
export default SvgBookMarkIcon;
