import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrossArrowsIconSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill="#fff">
        <path d="m12.478 20.16.962-1.57a.798.798 0 0 0-.256-1.097.78.78 0 0 0-1.084.26l-.963 1.57c-1.745 2.847-4.234 4.482-6.839 4.482H.783a.788.788 0 0 0-.783.798c0 .441.353.798.783.798h3.522c3.163-.013 6.14-1.92 8.173-5.241Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m27.82 2.063 3.94 3.483a.66.66 0 0 1 .007 1.013l-3.948 3.483a.866.866 0 0 1-.577.211.866.866 0 0 1-.577-.21.656.656 0 0 1 0-1.013l2.56-2.26h-3.768c-2.693 0-5.268 1.47-7.072 4.04l-.61.871a.844.844 0 0 1-.69.34.908.908 0 0 1-.425-.105c-.385-.211-.505-.65-.266-.99l.61-.872c2.104-2.997 5.19-4.718 8.453-4.718h3.776l-2.561-2.26a.656.656 0 0 1 0-1.013.887.887 0 0 1 1.147 0Zm2.209 3.987v.012l.013-.012-.013-.011v.011Zm-2.717 23.096a.817.817 0 0 1-.568-.223.727.727 0 0 1 0-1.062s2.514-2.396 2.507-2.396h-3.584c-3.213 0-6.249-1.825-8.319-5.01l-5.89-9.045c-1.775-2.72-4.315-4.284-6.96-4.284h-3.7C.359 7.126 0 6.784 0 6.362S.353 5.6.797 5.6h3.708c3.213 0 6.249 1.819 8.319 5.003l5.889 9.046c1.776 2.725 4.316 4.29 6.96 4.29h3.598l-2.52-2.397a.73.73 0 0 1 0-1.074.825.825 0 0 1 1.129 0l3.885 3.688a.73.73 0 0 1 0 1.074l-3.885 3.694a.817.817 0 0 1-.568.224Zm2.755-4.45-.013-.013v.024l.013-.012Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="rotate(90 16 16)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCrossArrowsIconSecondary;
