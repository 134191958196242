import * as React from 'react';
import { SVGProps } from 'react';

const SvgJivoMobile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={49}
      height={49}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M24.5 49C38.031 49 49 38.031 49 24.5S38.031 0 24.5 0 0 10.969 0 24.5 10.969 49 24.5 49Z"
          fill="#4B291C"
        />
        <path
          d="M24.537 10.236c-9.264 0-16.773 5.904-16.773 13.183 0 3.446 1.684 6.589 4.442 8.937-.105 2.29-.58 5.367-2.257 6.954 3.204 0 6.484-2.11 8.43-3.63 1.907.595 3.984.921 6.158.921 9.263 0 16.773-5.903 16.773-13.182 0-7.28-7.51-13.183-16.773-13.183Z"
          fill="#EFEFEF"
        />
        <path
          clipRule="evenodd"
          d="m25.043 15.174 4.774 4.399a.603.603 0 0 1-.055.94l-9.718 7.142a.669.669 0 0 1-.91-.115.605.605 0 0 1-.134-.378v-6.83c0-.166.069-.324.191-.44l4.944-4.711a.67.67 0 0 1 .908-.007Zm1 9.174 3.122-2.191a.668.668 0 0 1 .907.133.604.604 0 0 1 .126.368v4.382c0 .344-.291.622-.65.622a.668.668 0 0 1-.383-.12l-3.122-2.192a.604.604 0 0 1 0-1.002Zm-.982 1.428 2.066 1.436a.605.605 0 0 1 .19.798l-2.066 3.664a.664.664 0 0 1-1.142 0l-2.066-3.664a.605.605 0 0 1 .19-.798l2.066-1.436a.673.673 0 0 1 .762 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h49v49H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgJivoMobile;
