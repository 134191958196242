import * as React from 'react';
import { SVGProps } from 'react';

const SvgBonusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="#E4EFEF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m15.3 8 1.2 1.2L9.7 16l-1.2-1.2L15.3 8ZM4.5 4h16c1.11 0 2 .89 2 2v4a2 2 0 0 0 0 4v4c0 1.11-.89 2-2 2h-16a2 2 0 0 1-2-2v-4c1.11 0 2-.89 2-2a2 2 0 0 0-2-2V6a2 2 0 0 1 2-2Zm0 2v2.54a3.994 3.994 0 0 1 0 6.92V18h16v-2.54a3.994 3.994 0 0 1 0-6.92V6h-16ZM10 8c.83 0 1.5.67 1.5 1.5S10.83 11 10 11s-1.5-.67-1.5-1.5S9.17 8 10 8Zm5 5c.83 0 1.5.67 1.5 1.5S15.83 16 15 16s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5Z" />
    </svg>
  );
};
export default SvgBonusIcon;
