import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor2X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={16}
        cy={16}
        r={15}
        fill="#DCDCDC"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="m11.422 15.07 1.422-2.523h2.414l-2.406 4.14L15.359 21h-2.421l-1.508-2.656L9.93 21H7.5l2.508-4.313-2.399-4.14h2.422l1.39 2.523ZM24.047 21H16.25v-1.547l3.68-3.922c.505-.552.877-1.034 1.117-1.445.245-.412.367-.802.367-1.172 0-.505-.128-.901-.383-1.187-.255-.292-.62-.438-1.093-.438-.51 0-.915.177-1.211.531-.292.35-.438.81-.438 1.383h-2.266c0-.693.165-1.325.493-1.898a3.498 3.498 0 0 1 1.406-1.344c.604-.328 1.289-.492 2.055-.492 1.171 0 2.08.281 2.726.844.651.562.977 1.356.977 2.382 0 .563-.146 1.136-.438 1.72-.291.582-.791 1.262-1.5 2.038l-2.586 2.727h4.89V21Z"
        fill="#646464"
      />
    </svg>
  );
};
export default SvgFactor2X;
