import * as React from 'react';
import { SVGProps } from 'react';

const SvgBronzeStatus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.993 68C52.715 68 68 52.728 68 34.007 68 15.285 52.728 0 33.993 0 15.272 0 0 15.272 0 34.007 0 52.728 15.272 68 33.993 68Z"
        fill="#FFC250"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.947 32.067c.04.642.053 1.284.053 1.94C68 52.781 52.782 68 33.993 68 15.218 68 0 52.782 0 34.006c0-.655.027-1.297.053-1.939 1.003 17.88 15.82 32.068 33.94 32.068 18.134 0 32.95-14.188 33.953-32.068Z"
        fill="#EBB044"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.993 59.36c13.8 0 25.06-11.26 25.06-25.06 0-13.801-11.246-25.06-25.06-25.06-13.8 0-25.06 11.259-25.06 25.06 0 13.8 11.26 25.06 25.06 25.06Z"
        fill="#FF9838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.436 17.927a.666.666 0 0 0-.901.007l-9.6 9.211a.566.566 0 0 0-.179.409v13.357a.56.56 0 0 0 .124.349c.21.264.618.32.902.11l18.87-13.965a.557.557 0 0 0 .053-.875l-9.27-8.603Zm-1.771-.898a1.923 1.923 0 0 1 2.626-.021l9.268 8.602a1.812 1.812 0 0 1-.16 2.803L25.53 42.38a1.92 1.92 0 0 1-2.632-.337 1.814 1.814 0 0 1-.397-1.131V27.554c0-.498.206-.97.564-1.314l9.6-9.211Zm11.463 14.735a.663.663 0 0 0-.9-.128l-6.061 4.285a.611.611 0 0 0-.134.129.56.56 0 0 0 .134.806l6.062 4.285c.108.077.242.12.382.12.371 0 .633-.284.633-.587v-8.57c0-.12-.04-.239-.116-.34Zm-1.625-1.153a1.92 1.92 0 0 1 2.625.394c.24.316.372.7.372 1.098v8.57c0 1.04-.867 1.844-1.889 1.844-.396 0-.784-.122-1.108-.35l-6.062-4.286a1.814 1.814 0 0 1-.408-2.591c.115-.151.253-.284.408-.394l6.062-4.285Zm-7.965 8.104a.67.67 0 0 0-.76 0l-4.011 2.808a.56.56 0 0 0-.181.74l4.01 7.165a.657.657 0 0 0 1.122 0l4.011-7.166a.56.56 0 0 0-.18-.74l-4.011-2.806Zm-1.481-1.028a1.927 1.927 0 0 1 2.201 0l4.011 2.808a1.815 1.815 0 0 1 .557 2.38l-4.01 7.166c-.503.896-1.643 1.213-2.547.742a1.867 1.867 0 0 1-.769-.742l-4.01-7.166a1.815 1.815 0 0 1 .556-2.38l4.01-2.808Z"
        fill="#FFF3BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.986 32.067c.054.642.08 1.284.08 1.94 0 13.84-11.219 25.06-25.06 25.06-13.84 0-25.06-11.22-25.06-25.06 0-.656.027-1.298.08-1.94.99 12.945 11.795 23.122 24.98 23.122 13.186.013 24.004-10.177 24.98-23.122Z"
        fill="#FF8709"
      />
    </svg>
  );
};
export default SvgBronzeStatus;
