import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor5X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#FF9838"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="m32.063 12.86-8.694-1.278-3.886-7.966a1.095 1.095 0 0 0-1.965 0l-3.887 7.966-8.694 1.278a1.09 1.09 0 0 0-.626.322 1.116 1.116 0 0 0 .02 1.568l6.29 6.2-1.486 8.755a1.117 1.117 0 0 0 .435 1.084 1.087 1.087 0 0 0 1.154.083l7.776-4.134 7.776 4.134c.212.114.459.152.695.11.596-.103.997-.674.894-1.277l-1.486-8.755 6.29-6.2a1.11 1.11 0 0 0 .318-.634 1.102 1.102 0 0 0-.924-1.256Z"
        fill="#FFAD60"
      />
      <path
        d="m12.023 14.547 1.399 2.57 1.437-2.57h2.399l-2.39 4.133 2.5 4.32H14.96l-1.524-2.719L11.915 23H9.508l2.5-4.32-2.39-4.133h2.405Zm8.54 1.789h1.203c.385 0 .703-.065.953-.195.25-.136.435-.323.554-.563.125-.245.188-.528.188-.851 0-.292-.057-.55-.172-.774a1.165 1.165 0 0 0-.508-.531c-.229-.13-.518-.195-.867-.195a1.79 1.79 0 0 0-.766.164c-.234.109-.421.263-.562.46-.14.198-.211.438-.211.72h-2.258c0-.626.167-1.17.5-1.633a3.4 3.4 0 0 1 1.36-1.086 4.442 4.442 0 0 1 1.875-.391c.77 0 1.445.125 2.023.375.578.245 1.029.607 1.352 1.086.322.479.484 1.073.484 1.781 0 .36-.083.709-.25 1.047a2.94 2.94 0 0 1-.719.906c-.307.266-.682.48-1.125.64-.443.157-.94.235-1.492.235h-1.563v-1.195Zm0 1.71v-1.163h1.562c.62 0 1.164.07 1.633.21.469.141.862.345 1.18.61.317.26.557.57.718.93.162.354.242.747.242 1.18 0 .53-.101 1.005-.304 1.421-.203.412-.49.76-.86 1.047a3.923 3.923 0 0 1-1.28.657 5.59 5.59 0 0 1-1.602.218c-.48 0-.951-.065-1.415-.195a4.209 4.209 0 0 1-1.25-.602c-.37-.27-.666-.609-.89-1.015-.219-.412-.328-.899-.328-1.461h2.258c0 .291.072.552.218.781.146.23.347.409.602.54.26.13.55.194.867.194.36 0 .667-.065.922-.195a1.41 1.41 0 0 0 .594-.562c.14-.245.21-.529.21-.852 0-.417-.075-.75-.226-1a1.345 1.345 0 0 0-.648-.562 2.54 2.54 0 0 0-1-.18h-1.203Z"
        fill="#643000"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor5X;
