import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m26.605 10.345-7.439-1.081-3.325-6.742a.932.932 0 0 0-.425-.424.941.941 0 0 0-1.257.424l-3.325 6.742-7.439 1.08a.936.936 0 0 0-.518 1.6l5.382 5.247-1.272 7.41a.936.936 0 0 0 1.36.987L15 22.09l6.653 3.498a.936.936 0 0 0 1.36-.987l-1.272-7.41 5.382-5.247a.935.935 0 0 0 .272-.536.934.934 0 0 0-.79-1.063Z"
        fill="#F88360"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.811 14.998a1.84 1.84 0 0 0-.526-.601 3.484 3.484 0 0 0-.8-.436c-.308-.123-.646-.24-1.011-.353-.19-.057-.357-.11-.5-.16a2.123 2.123 0 0 1-.36-.158.713.713 0 0 1-.217-.182.382.382 0 0 1-.072-.233.38.38 0 0 1 .067-.227.491.491 0 0 1 .18-.151.955.955 0 0 1 .265-.084c.1-.018.207-.027.32-.027.111 0 .22.016.327.046a1.972 1.972 0 0 1 .577.27c.085.058.161.114.23.169l.047.038c.049.038.092.07.132.096.04.025.084.038.133.038a.26.26 0 0 0 .175-.081l.792-.782a.767.767 0 0 0 .102-.12.208.208 0 0 0 .033-.114c0-.041-.023-.091-.07-.15a1.409 1.409 0 0 0-.189-.183 2.91 2.91 0 0 0-.276-.2 6.077 6.077 0 0 0-.335-.196 3.463 3.463 0 0 0-.851-.313c-.088-.02-.178-.031-.267-.045v-.648a.177.177 0 0 0-.085-.154.297.297 0 0 0-.164-.057h-.973c-.076 0-.133.02-.17.063a.21.21 0 0 0-.058.148v.672c-.107.02-.22.035-.32.064a2.727 2.727 0 0 0-.868.4c-.24.172-.424.38-.553.623-.13.242-.195.512-.195.81 0 .278.052.531.155.761.104.23.26.44.466.63.208.191.467.364.777.518.31.155.673.3 1.087.434.093.029.203.065.332.108.127.043.25.095.367.156.117.06.217.13.298.208a.362.362 0 0 1 .122.266.74.74 0 0 1-.044.254.52.52 0 0 1-.148.21.758.758 0 0 1-.283.147 1.508 1.508 0 0 1-.442.055c-.19 0-.372-.03-.546-.09a2.563 2.563 0 0 1-.52-.255 3.079 3.079 0 0 1-.314-.223 8.896 8.896 0 0 0-.225-.18.976.976 0 0 0-.124-.086.205.205 0 0 0-.098-.029c-.045 0-.104.025-.177.072l-.895.791c-.08.067-.119.128-.119.182 0 .051.04.115.119.192l.02.02c.008.005.014.012.021.018l.016.015c.166.169.356.327.572.474.216.147.445.276.688.386.244.11.498.197.761.259.023.005.047.007.07.012v.704c0 .14.077.211.228.211h.973c.166 0 .249-.07.249-.21v-.69a3.67 3.67 0 0 0 .455-.1c.366-.11.684-.265.956-.467.27-.201.483-.444.64-.73.154-.286.232-.605.232-.956 0-.333-.063-.616-.189-.848Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgStarDollarIcon;
