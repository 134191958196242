import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor1X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
          fill="#FF9838"
        />
        <path d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z" />
        <path
          d="m7.518 9.66 1.048 1.928L9.645 9.66h1.798l-1.793 3.1L11.525 16H9.721l-1.143-2.04L7.436 16H5.63l1.875-3.24-1.793-3.1h1.805Zm8.789-2.209V16h-1.688V9.396l-2.027.645v-1.33l3.533-1.26h.182Z"
          fill="#643000"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgNotVerifyFactor1X;
