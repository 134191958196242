import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 14.667A6.666 6.666 0 1 1 8 1.334a6.666 6.666 0 0 1 0 13.333Zm0-1.333A5.333 5.333 0 1 0 8 2.667a5.333 5.333 0 0 0 0 10.667Zm-.667-8.667h1.334V6H7.333V4.667Zm0 2.667h1.334v4H7.333v-4Z"
        fill="#60A5FA"
      />
    </svg>
  );
};
export default SvgInfoIcon;
