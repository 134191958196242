import * as React from 'react';
import { SVGProps } from 'react';

const SvgFortuneWheel = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={134}
      height={83}
      viewBox="0 0 134 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67 133.818c37.003 0 67-29.956 67-66.909S104.003 0 67 0 0 29.956 0 66.91c0 36.952 29.997 66.908 67 66.908Z"
        fill="#F88360"
      />
      <path
        d="M67 129.804c33.673 0 60.97-27.26 60.97-60.887 0-33.628-27.297-60.888-60.97-60.888-33.672 0-60.97 27.26-60.97 60.888 0 33.627 27.297 60.887 60.97 60.887Z"
        fill="#944714"
      />
      <path
        d="M67 127.128c33.303 0 60.3-26.961 60.3-60.219 0-33.257-26.997-60.218-60.3-60.218S6.7 33.651 6.7 66.91s26.997 60.219 60.3 60.219Z"
        fill="#FFFFF5"
      />
      <path
        d="m67 66.91-39.798 39.743c-4.824-4.817-8.576-10.17-11.256-15.924L67 66.91Z"
        fill="#F3F3F3"
      />
      <path
        d="M43.148 117.894c6.03 2.81 12.462 4.416 18.894 5.085L67 66.909l-23.852 50.985Zm38.458 3.346c6.298-1.606 12.194-4.416 17.688-8.297L67 66.909l14.606 54.331Z"
        fill="#F3F3F3"
      />
      <path
        d="M34.706 20.876c-2.68 1.873-5.226 3.88-7.504 6.29a54.937 54.937 0 0 0-6.298 7.493L67 66.91 34.706 20.876Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.596 52.323c-1.742 6.29-2.278 12.98-1.742 19.404L67 66.909 12.596 52.323Zm59.362-41.35c-6.566-.535-13.132 0-19.43 1.74L67 66.909l4.958-55.936Zm34.84 16.192c-4.824-4.817-10.184-8.564-15.946-11.24L67 66.909l39.798-39.744Zm6.298 71.995c3.886-5.487 6.566-11.509 8.308-17.664L67 66.909l46.096 32.25Z"
        fill="#F3F3F3"
      />
      <path
        d="M123.012 61.958c-.536-6.423-2.278-12.846-5.092-18.868L67 66.91l56.012-4.952Z"
        fill="#F3F3F3"
      />
      <path
        d="m67 66.91-23.852 50.984c-5.762-2.676-11.256-6.423-15.946-11.241L67 66.909Z"
        fill="#404865"
      />
      <path
        d="M62.042 122.845c6.566.536 13.132 0 19.43-1.739L67 66.909l-4.958 55.936Z"
        fill="#404865"
      />
      <path
        d="M10.988 71.86c.536 6.424 2.278 12.847 5.092 18.87L67 66.91l-56.012 4.95Zm9.916-37.2c-3.886 5.486-6.566 11.508-8.308 17.663L67 66.91 20.904 34.66Zm69.948-18.735c-6.03-2.81-12.462-4.416-18.894-5.085L67 66.91l23.852-50.985ZM67 66.91 52.394 12.58c-6.298 1.605-12.194 4.415-17.688 8.296L67 66.91Zm32.294 46.033c2.68-1.874 5.226-3.881 7.504-6.29a54.885 54.885 0 0 0 6.298-7.493L67 66.91l32.294 46.033Z"
        fill="#404865"
      />
      <path
        d="M121.404 81.495c1.742-6.289 2.278-12.98 1.742-19.403L67 66.909l54.404 14.586Z"
        fill="#404865"
      />
      <path
        d="M118.054 43.09c-2.68-5.755-6.432-11.241-11.256-15.925L67 66.91l51.054-23.82Z"
        fill="#404865"
      />
      <path
        opacity={0.5}
        d="M123.28 66.91c0-31.047-25.192-56.204-56.28-56.204S10.72 35.863 10.72 66.909c0 10.706 2.948 20.742 8.174 29.173a54.866 54.866 0 0 1-6.834-26.496C12.06 39.343 36.716 14.72 67 14.72s54.94 24.623 54.94 54.866a54.866 54.866 0 0 1-6.834 26.496c5.226-8.431 8.174-18.467 8.174-29.173Zm-42.21 1.337c0 7.762-6.298 14.051-14.07 14.051s-14.07-6.29-14.07-14.05c0-7.762 6.298-14.052 14.07-14.052s14.07 6.29 14.07 14.051Z"
        fill="#8C8C8C"
      />
      <path
        d="m79.462 61.958-10.05-25.56c-.402-.936-1.34-1.74-2.546-1.74-1.206 0-2.144.67-2.546 1.74l-10.05 25.56c-.536 1.472-.938 3.212-.938 4.817 0 7.36 6.03 13.382 13.4 13.382s13.4-6.022 13.4-13.382c.268-1.606 0-3.345-.67-4.817Z"
        fill="#FFFFF5"
      />
      <path
        d="M67 77.48c-5.896 0-10.72-4.817-10.72-10.705 0-1.338.268-2.676.67-3.88L67 37.335l10.05 25.56c.536 1.204.67 2.542.67 3.88 0 6.022-4.824 10.706-10.72 10.706Z"
        fill="#F88360"
      />
      <path
        d="M67 72.128c2.96 0 5.36-2.397 5.36-5.353s-2.4-5.353-5.36-5.353a5.356 5.356 0 0 0-5.36 5.353c0 2.956 2.4 5.353 5.36 5.353Z"
        fill="#404865"
      />
      <path
        d="M67 68.782a2.008 2.008 0 1 0 0-4.014 2.008 2.008 0 1 0 0 4.014Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgFortuneWheel;
